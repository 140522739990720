export const routes = {
  home: '/',
  work: '/projects/',
  caseStudies: '/projects/case-studies/',
  caseStudy: ({ title }: { title: string }) =>
    `/projects/case-studies/${title.replace(/\s+/g, '-').replace(/\.+$/, '').replace(':', '').replace("'", '').toLowerCase()}`,
  solutions: '/solutions/',
  contact: '/contact',
  process: '/approach',
  privacyPolicy: '/privacy-policy',
  ideas: '/ideas/',
  idea: ({ slug }: { slug: string }) => `/ideas/${slug}`,
  // @TODO: Add it
  careers: '/careers',
};
