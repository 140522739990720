import { HeaderQuery } from '@/generated/graphql';
import { routes } from '@/routes/routes';
import { Container } from '@/ui/components/container/Container';
import clsx from 'clsx';
import { Link, graphql, useStaticQuery } from 'gatsby';
import React, { useRef } from 'react';
import { Logo } from '../../../logo/Logo';
import * as styles from './Header.module.scss';
import { MobileNavMenu } from './mobileNavMenu/MobileNavMenu';
import { NavMenu } from './navMenu/NavMenu';

export const Header = ({ className, isContentLimited }: { className: string; isContentLimited?: boolean }) => {
  const headerRef = useRef<HTMLDivElement | null>(null);
  const strapiNavData = useStaticQuery<HeaderQuery>(HEADER_QUERY);

  const handleContactUsClick = () => {
    window.location.href = 'mailto:info@cochraninc.com';
  };

  return (
    <div ref={headerRef} className={clsx(styles.headerWrapper, className)}>
      <Container>
        <header className={styles.header}>
          <Link to={routes.home}>
            <Logo className={styles.header__logo} />
          </Link>
          {isContentLimited ? null : <NavMenu data={strapiNavData} />}
          <div className={styles.header__btnMobileMenu}>
            {process.env.GATSBY_IS_ONE_PAGER_ENABLED === 'true' ? (
              <button className={styles.header__button} onClick={handleContactUsClick}>
                Contact us
              </button>
            ) : (
              <Link className={styles.header__button} to={strapiNavData.strapiHeader?.contactButtonLink || ''}>
                {strapiNavData.strapiHeader?.contactButtonName || ''}
              </Link>
            )}
            {isContentLimited ? null : <MobileNavMenu data={strapiNavData} />}
          </div>
        </header>
      </Container>
    </div>
  );
};

const HEADER_QUERY = graphql`
  query Header {
    strapiHeader {
      contactButtonName
      contactButtonLink
      headerSections {
        mainLink {
          url
          tag
        }
        sectionLinks {
          url
          tag
          name
          shouldOpenInNewTab
          firstSubsection {
            name
            headerLinks {
              name
              url
              shouldOpenInNewTab
            }
          }
          secondSubsection {
            name
            headerLinks {
              name
              url
              shouldOpenInNewTab
            }
          }
        }
      }
      firstLink {
        tag
        url
        shouldOpenInNewTab
      }
      secondLink {
        tag
        url
        shouldOpenInNewTab
      }
      contactNumbers {
        name
        number
      }
      socialLinks {
        title
        url
        shouldOpenInNewTab
      }
    }
  }
`;
