// extracted by mini-css-extract-plugin
export var contactSection = "Footer-module--contactSection--4ed1b";
export var contactSection__desc = "Footer-module--contactSection__desc--86332";
export var contactSection__phoneNumber = "Footer-module--contactSection__phoneNumber--a96a9";
export var contactSection__service = "Footer-module--contactSection__service--34d36";
export var contactSection__serviceBtn = "Footer-module--contactSection__serviceBtn--31076";
export var contactSection__serviceDesc = "Footer-module--contactSection__serviceDesc--1ee14";
export var contactSection__serviceTag = "Footer-module--contactSection__serviceTag--ffec4";
export var contactSection__title = "Footer-module--contactSection__title--0f10f";
export var footer = "Footer-module--footer--8a4f4";
export var footer__copyrightInfo = "Footer-module--footer__copyrightInfo--98ee4";
export var footer__linkIcon = "Footer-module--footer__linkIcon--fcf0d";
export var footer__links = "Footer-module--footer__links--ace07";
export var footer__list = "Footer-module--footer__list--1f53d";
export var footer__list___locations = "Footer-module--footer__list___locations--b79a4";
export var footer__outer = "Footer-module--footer__outer--90d39";
export var footer__privacyPolicyText = "Footer-module--footer__privacyPolicyText--8f757";
export var footer__section = "Footer-module--footer__section--1f8c5";